"use client";

import * as React from "react";
import { cn } from "@/lib/utils";
import { type DialogProps } from "@radix-ui/react-dialog";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button, buttonVariants } from "@/components/ui/button";

export function AboutUsDialog() {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Rreth nesh</DialogTitle>
            <DialogDescription>MAL&KET</DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <a
        target="_blank"
        className={cn(buttonVariants({ variant: "outline" }))}
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <span className="hidden sm:block">Rreth nesh</span>
        <span className="sm:hidden">Rreth nesh</span>
      </a>
    </>
  );
}
