import(/* webpackMode: "eager", webpackExports: ["AboutUsDialog"] */ "/workspace/components/about-us-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClearHistory"] */ "/workspace/components/clear-history.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarItems"] */ "/workspace/components/sidebar-items.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarMobile"] */ "/workspace/components/sidebar-mobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarToggle"] */ "/workspace/components/sidebar-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeToggle"] */ "/workspace/components/theme-toggle.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/components/ui/icons.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
